import React from "react";
import Calendar from "@ericz1803/react-google-calendar";
import "../styles.css"
import { css } from "@emotion/react";

const API_KEY = "AIzaSyCesfSwLI1nDnv5Fi-ohSBL2coXbES_dTA";
let calendars = [
  {calendarId: "52d618688fbdc436139fa7a3bf2b04c4d4faf19e7680ec0f41ef3580885f78a4@group.calendar.google.com",
   color: "#FFFFFF"},
 
];

let styles = {
  //you can use object styles (no import required)
  calendar:{
    color:"#FFFFFF",
  },
  eventText: css`
  /* highlight today by making the text red and giving it a red border */
  color: white;
  text-wrap: balance;
`,
  
}
  

class EventsCalendar extends React.Component {
  render() {
    return (
      <div>
      <h1 className="calender_heading">Upcoming Events</h1>
    <div className="calender_container">
      
      <div className="calender_bg">
        <Calendar apiKey={API_KEY} calendars={calendars} styles = {styles}/>
      </div>
    </div>
    </div>
    )
  }
}
export default EventsCalendar;
import React from 'react'


import {Scrollbar, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';
import {AiOutlineArrowRight} from 'react-icons/ai'
import { AiOutlineArrowLeft } from 'react-icons/ai';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import "../components/slider.css"

import {EffectCoverflow,Pagination,Navigation} from 'swiper/modules'

import slide_image_1 from "../assets/img/game_night_1.png"
import slide_image_2 from "../assets/img/game_night.png"
import slide_image_3 from "../assets/img/fall_guys_friday.png"
import slide_image_4 from "../assets/img/Smash_bros_1.png"
import slide_image_5 from "../assets/img/giveaway_1.png"
import slide_image_6 from "../assets/img/ghg_moa_smash.png"
import hiring from "../assets/img/hiring.png"



export const EventsSlider = () => {
    const slides = [
        hiring,slide_image_1,slide_image_2,slide_image_3,slide_image_4,slide_image_5
    ]
    return(
      <div className='slider_container' id = "events">
        <div className="container">
          <h1 className="heading">Past Events</h1>
          <Swiper
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            slidesPerView={'auto'}
            coverflowEffect={{
              rotate: 0, 
              stretch: 0,
              depth: 100,
              modifier: 2.5,
            }}
            pagination={{ el: '.swiper-pagination', clickable: true }}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
              clickable: true,
            }}
            modules={[EffectCoverflow, Pagination, Navigation]}
            className="swiper"
          >
            {slides.map(slide => (
                  <SwiperSlide >
                      <img src = {slide} alt = "slide_img"/>
                  </SwiperSlide>
              ))}
            
    
            <div className="slider-controler">
              <div className="swiper-button-prev slider-arrow">
                <AiOutlineArrowLeft name="arrow-back-outline"></AiOutlineArrowLeft>
              </div>
              <div className="swiper-button-next slider-arrow">
                <AiOutlineArrowRight name="arrow-forward-outline"></AiOutlineArrowRight>
              </div>
              <div className="swiper-pagination"></div>
            </div>
          </Swiper>
       </div>
      </div>  
    )
}


/*
 {slides.map(slide => (
                <SwiperSlide >
                    <img src = {slide} alt = "slide_img"/>
                </SwiperSlide>
            ))}
*/
import { useState, useEffect } from "react";

import headerImg from "../assets/img/tiktok.png";
import banner from "../assets/img/gamingbackground.png"
import ghg_discord_logo from "../assets/img/GHG_Discord_Logo.png"
import 'animate.css';
import "../styles.css"
import TrackVisibility from 'react-on-screen';

import img1 from "../assets/img/amongus.png"
import img2 from "../assets/img/LoL_icon.svg.png";
import img3 from "../assets/img/Valorant.png";
import img4 from "../assets/img/Overwatch.png"



export const Banner = () => {
  
  const toRotate = ["Among us", "League Of Legends", "Valorant", "Overwatch"];
  const images = [img1, img2, img3,img4];


  const animationTime = 3000; // Total animation time in milliseconds
  const typingDuration = animationTime / (2 * toRotate.length); // Duration to type each phrase
  const deletionDuration = typingDuration; // Duration to delete each phrase

  
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(typingDuration);
  
  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => {
      clearInterval(ticker);
    };
  }, [text, delta]);

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(deletionDuration);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setDelta(deletionDuration);
      
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum((prevLoopNum) => prevLoopNum + 1);
      setDelta(typingDuration);
      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, deletionDuration); 
    }
  };

  return (
    <div className="banner" id="home" >
          <div className="text_cointainer">
            <h1 className= "tagline"> Welcome to Golden Hawk Gaming!</h1>
            <h1>{`We play`} <span className="txt-rotate" data-period="1000"><span className="wrap">{text}</span></span></h1>
          </div>
         <div  className="img_container">
  
            <img className='game_imgs' src={images[currentImageIndex]} alt="Carousel Image" />
         </div>
    </div>

  )
}

/*

  */
import React from 'react'


import {Scrollbar, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';
import {AiOutlineArrowRight} from 'react-icons/ai'
import { AiOutlineArrowLeft } from 'react-icons/ai';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import "../components/slider.css"
import slides from '../execs.json'


import {EffectCoverflow,Pagination,Navigation} from 'swiper/modules'


import { Profile } from './Profile';



export const ProfileSlider = () => {
    return(
      <div className='Card-Slider' id = "execs">
         <div className="container">
          <h1 className="heading">Execs</h1>
          <Swiper
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            slidesPerView={'auto'}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 2.5,
            }}
            pagination={{ el: '.swiper-pagination', clickable: true }}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
              clickable: true,
            }}
            modules={[EffectCoverflow, Pagination, Navigation]}
            className="swiper_container"
          >
            {slides.map((slide,index) => (
                  <SwiperSlide >
                      <Profile key = {index} member={slide}/>
                  </SwiperSlide>
              ))}
            
    
            <div className="slider-controler">
    
              <div className="swiper-pagination"></div>
            </div>
          </Swiper>
       </div>
       </div>
    
    )
}


/*
 {slides.map(slide => (
                <SwiperSlide >
                    <img src = {slide} alt = "slide_img"/>
                </SwiperSlide>
            ))}
*/
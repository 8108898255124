import React,{useState}from 'react'

export const Profile = ({member}) => {


  return (
    <div className='Card-Container'>
        <div className='Card'>
      <div className='upper-container'>
        <div className='image-container'>
          <img src={member.image} alt="Profile" width="100px" height="100px" />
        </div>
        <div className='lower-container'>
          <h3>{member.discord}</h3>
          <h4>{member.title}</h4>
          <p>{member.description}</p>
        </div>
      </div>
    </div>
    </div>
  )
}

import React from 'react';
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { EventsSlider } from "./components/EventsSlider";

import EventsCalendar from "./components/Calender";

import { ProfileSlider } from "./components/ProfileSlider";
import { Footer } from "./components/Footer";

function App() {
  return (
    <div>
      <NavBar />
      <Banner />
      <EventsSlider />
      <EventsCalendar />
      <ProfileSlider />
      <Footer />
    </div>
  );
}

export default App;
